import React from "react";
import Layout from "@layout";
import SEO from "@components/seo";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import Footer from "../layouts/footer/layout-01";
import Header from "../layouts/header/layout-dark";
import { normalizedData } from "@utils/functions";
import Button from "../components/shared/button";
const NotFoundPage = ({ data }) => {
    const globalContent = normalizedData(data?.allGeneral?.nodes || []);
    return (
        <Layout>
            <SEO title="404: Not found" pathname="/" />
            <Header data={{ ...globalContent?.["menu"] }} />
            <div className="not-found section-space-ptb border-b">
                <div className="container-full text-center">
                    <div className="error_title mb-10">
                        <h1 className="text-8xl lg:text-[200px]">404</h1>
                        <p className="text-lg md:text-xl lg:text-3xl font-semibold leading-[1.4] mb-10">
                            We’re unable to find a page you are looking <br />{" "}
                            for, Try later or click the button.
                        </p>
                    </div>
                    <Button
                        size="md"
                        color="primary"
                        path="/"
                        hover="hoverDark"
                        className="text-white"
                    >
                        GO TO HOME
                    </Button>
                </div>
            </div>
            <Footer data={{ ...globalContent?.["footer"] }} />
        </Layout>
    );
};

NotFoundPage.propTypes = {
    data: PropTypes.shape({
        allGeneral: PropTypes.shape({
            nodes: PropTypes.arrayOf(PropTypes.shape({})),
        }),
    }),
};
export const query = graphql`
    query NotFoundPageQuery {
        allGeneral {
            nodes {
                section
                id
                menu {
                    ...Menu
                }
                footer {
                    ...Footer
                }
            }
        }
    }
`;
export default NotFoundPage;
